<template>
  <div class="st-divider" :class="[type, `orientation-${orientation}`]" />
</template>

<script setup lang="ts">
interface Props {
  align?: 'start' | 'end' | 'center'
  spacing?:
    | number
    | {
        top?: number
        bottom?: number
      }
  type?: 'line' | 'dot'
  orientation?: 'v' | 'h'
}

const {
  align = 'center',
  spacing = 0,
  type = 'line',
  orientation = 'h',
} = defineProps<Props>()

const offset = computed(() => {
  const isObjSize = typeof spacing === 'object'
  const topSize = (isObjSize ? spacing.top : spacing) ?? 0
  const botSize = (isObjSize ? spacing.bottom : spacing) ?? 0
  let usedTopSize = `var(--spacing-${topSize < 100 ? '0' : ''}${topSize})`
  if (!topSize) {
    usedTopSize = '0'
  }
  let usedBotSize = `var(--spacing-${botSize < 100 ? '0' : ''}${botSize})`
  if (!botSize) {
    usedBotSize = '0'
  }
  return {
    start: align === 'start' ? 0 : usedTopSize,
    end: align === 'end' ? 0 : usedBotSize,
  }
})
</script>

<style scoped>
.st-divider {
  width: 100%;
  height: 0;
  margin: v-bind('offset.start') 0 v-bind('offset.end');

  &::before {
    content: '';

    display: block;

    width: 100%;
    height: 100%;

    border-bottom: var(--border-size) var(--border-style) var(--border-primary);
  }

  &.orientation-v {
    width: 0;
    height: 100%;
    margin: 0 v-bind('offset.end') 0 v-bind('offset.start');

    &::before {
      border-left: var(--border-size) var(--border-style) var(--border-primary);
    }
  }

  &.line {
    --border-style: solid;
    --border-size: 0.5px;
  }

  &.dot {
    --border-style: dotted;
    --border-size: 3px;
  }
}
</style>
